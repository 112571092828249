import { api } from "@/base/utils/request";
import download from '@/base/utils/download'

export const getCheckList = (data) => {
  return api({
    url: '/admin/venue/reserve/index',
    method: 'post',
    data
  })
}
// 获取所属组织列表
export const organizationsList = data => {
  return api({
    url: "/admin/venue/venue/organizations",
    method: "post",
    data
  });
};

// 导出：/admin/venue/reserve/export
export const exportVenueReserve = (data) => {
  return download({
    url: "/admin/venue/reserve/export",
    method: "post",
    data,
    download: true
  });
}
