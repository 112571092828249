import api from '@/base/utils/request'

export const getVenueCondition = (data) => {
  return api({
    url: '/admin/venue/venueCondition/index',
    method: 'post',
    data
  })
}

export const getVenueInfo = (id) => {
  return api({
    url: '/admin/venue/venue/getInfo',
    method: 'post',
    data: { id },
  })
}
export const getReserveRecords = (data) => {
  return api({
    url: '/admin/venue/venueCondition/reserveRecords',
    method: 'post',
    data,
  })
}
// 获取上级组织列表
export const ParentList = (data) => {
  return api({
    url: '/admin/cyc/Organization/OrganizationsTree',
    method: 'post',
    data,
  })
}

export const saveVenueInfo = (data) => {
  return api({ url: '/admin/venue/venue/saveInfo', method: 'post', data })
}

export const getVenueSettings = (id) => {
  return api({
    url: '/admin/venue/venue/getSettings',
    method: 'post',
    data: { id },
  })
}

export const saveVenueSettings = (data) => {
  return api({ url: '/admin/venue/venue/saveSettings', method: 'post', data })
}

export const getVenueReserveInfo = (id) => {
  return api({
    url: '/admin/venue/venue/getPreSettings',
    method: 'post',
    data: { id },
  })
}

export const saveVenueReserveInfo = (data) => {
  return api({
    url: '/admin/venue/venue/savePreSettings',
    method: 'post',
    data,
  })
}

export const getVenueBaseInfo = (id) => {
  return api({
    url: '/admin/venue/venueCondition/baseInfo',
    method: 'post',
    data: { id },
  })
}

export const checkUserClientInfo = () => {
  return api({
    url: '/admin/venue/venue/checkUserClientInfo',
    method: 'post',

  })
}