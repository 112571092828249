import api from '@/base/utils/request'

export const getAllFacilities = (data) => {
  return api({ url: '/admin/venue/facility/all', method: 'post', data })
}

export const getAllFacilityIcons = () => {
  return api({ url: '/admin/venue/facility/iconList', method: 'post' })
}

export const saveFacility = (data) => {
  return api({ url: '/admin/venue/facility/save', method: 'post', data })
}

export const removeFacility = (ids) => {
  return api({
    url: '/admin/venue/facility/del',
    method: 'post',
    data: {
      id: ids,
    },
  })
}
