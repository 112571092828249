import api from '@/base/utils/request'

export const getVenueList = (data) => {
  return api({ url: '/admin/venue/venue/index', method: 'post', data })
}

export const getOrgList = (data) => {
  return api({ url: '/admin/venue/venue/organizations', method: 'post', data })
}

export const changeStatus = (data) => {
  return api({url: '/admin/venue/venue/updateStatus', method: 'post', data})
}

export const removeVenue = (ids) => {
  return api({url: '/admin/venue/venue/del', method: 'post', data: {
    ids
  }})
}
export const softDelete = (ids) => {
  return api({url: '/admin/venue/venue/softDelete', method: 'post', data:{
    ids
  }})
}
export const putBack = (ids) => {
  return api({url: '/admin/venue/venue/putBack', method: 'post', data:{
    ids
  }})
}
export const changeAdminUser = (data) => {
  return api({
    url: "/admin/venue/venue/changeAdminUser",
    method: "post",
    data
  });
 
}
//获取用户列表
export const userList = data => {
  return api({
    url: "/admin/admin/user/index",
    method: "post",
    data
  });
}
