<!--
 * @Author: mulingyuer
 * @Date: 2021-04-09 17:49:59
 * @LastEditTime: 2021-09-09 10:09:35
 * @LastEditors: aleaner
 * @Description: 筛选
 * @FilePath: \vote\components\vote-list\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <layout-filter :onFilter="onFilter" :onReset="onReset">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" size="medium" class="filter-from">
      <div class="filter-item">
        <!-- <el-form-item label="审批编号：" prop="keyword">
            <el-input v-model="form.keyword" placeholder="请输入" clearable></el-input>
          </el-form-item> -->
          <el-form-item label="场地名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="申请人：" prop="reserve_user_name">
          <el-input v-model="form.reserve_user_name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        
        <el-form-item label="场地标题：" prop="purpose">
          <el-input v-model="form.purpose" placeholder="请输入" clearable></el-input>
        </el-form-item>
      </div>
 
      <div class="filter-item">
        <el-form-item label="场地使用类型：" prop="type">
          <el-select v-model="form.type">
            <el-option v-for="c in typeList" :key="c.id" :label="c.name" :value="c.id" />
          </el-select>
        </el-form-item>
       


     
        <el-form-item label="所属架构：" prop="organization_id">

          <el-cascader v-model="form.organization_id" :options="organizationsList" :props="{
            emitPath: false,
            label: 'name',
            value: 'id',
            children: 'children',
            checkStrictly: true
            // multiple: true,
          }"></el-cascader>

        </el-form-item>
      </div>
      <div class="filter-item">
          <DatePeriodPicker label="开放时间：" start-placeholder="开始日期" :start-time.sync="form.start_time"
            start-prop="start_time" end-placeholder="结束日期" :end-time.sync="form.end_time" end-prop="end_time" />
        </div>
    </el-form>
  </layout-filter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { allCategory } from "@/modules/approval/api/category-list";
import { organizationsList } from '@/modules/venue/api/check-list'
// import { exportDocument } from "../../api/vote-list";
export default {
  name: 'condition-filter ',
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        "page": 1,
        "page_size": 10,
        "order_by": "create_time",
        "is_desc": 1,
        "name": '',
        "reserve_user_name": "", 
        organization_id: '',
        "check_status": -1, 
        start_time:-1,
        end_time:-1,
        purpose:'',
        type:-1
      },
      rules: {},
      categories: [],
      statusList: [
        {
          id: -1,
          name: "全部"
        },
        {
          id: 0,
          name: "待审批"
        },
        {
          id: 1,
          name: "已通过"
        },
        {
          id: 2,
          name: "不通过"
        }
      ],
      typeList:[
        {
          id: -1,
          name: "全部"
        },
        {
          id: 0,
          name: "活动"
        },
        {
          id: 1,
          name: "会议" }
      ],
      organizationsList: [],
    };
  },
  created() {
    allCategory().then(res => {
      this.categories = res.data
    }).catch(() => {
    })
    this.getOrganizationsList()
  },
  methods: {
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //导出
    onExport() {
      // return exportDocument({ ...this.exportData, ...this.form }).catch(
      //   (err) => {
      //     // console.log(err);
      //   }
      // );
    },
    getOrganizationsList() {
      organizationsList().then(res => {
        this.organizationsList = res.data
      })
    }
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped></style>
  