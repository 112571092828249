import api from '@/base/utils/request'

export * from './list'

export * from './facility'

export * from './venue'

export * from './category'

// 获取架构职务
export const positionLevelForVenue = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data,
    notCancel: true
  });
};