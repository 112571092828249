import api from '@/base/utils/request'

export const getVenueCategory = () => {
  return api({ url: '/admin/venue/venueCategory/all', method: 'post' })
}

export const addOrEditVenueCategory = (data) => {
  return api({ url: '/admin/venue/venueCategory/save', method: 'post', data })
}

export const moveVenueCategory = (data) => {
  return api({ url: '/admin/venue/venueCategory/move', method: 'post', data })
}

export const updateVenueCategoryShow = (data) => {
  return api({url:'/admin/venue/venueCategory/isShow', method: 'post', data})
}

export const updateVenueCategorySort = (data) => {
  return api({url:'/admin/venue/venueCategory/changeSort', method: 'post', data})
}

export const deleteVenueCategory = (ids) => {
  return api({url:'/admin/venue/venueCategory/delete', method: 'post', data: {id: ids}})
}