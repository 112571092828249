import { api } from "@/base/utils/request";

export const getCategoryList = (data) => {
  return api({
    url: '/admin/approval/category/index',
    method: 'post',
    data
  })
}

export const updateCategorySort = (data) => {
  return api({
    url: '/admin/approval/category/saveSort',
    method: 'post',
    data
  })
}

export const updateCategoryShow = (data) => {
  return api({
    url: '/admin/approval/category/changeShow',
    method: 'post',
    data
  })
}

export const addOrEditCategory = (data) => {
  return api({
    url: '/admin/approval/category/save',
    method: 'post',
    data
  })
}

export const moveCategory = (data) => {
  return api({
    url: '/admin/approval/category/move',
    method: 'post',
    data
  })
}

export const allCategory = () => {
  return api({
    url: '/admin/approval/category/all',
    method: 'post'
  })
}

export const deleteCategory = (data) => {
  return api({
    url: '/admin/approval/category/delete',
    method: 'post',
    data: {
      id: data
    }
  })
}
