<template>
    <div>
      <list-layout
        ref="listLayout"
        :filter-form="filterForm"
        :tab-arr="tabArr"
        :thead="thead"
        :on-fetch="getList"
        @command="handleOperation"
      >
       <template #filter>
        <condition-filter   :export-data="{}" :filter-change="onFilter" />
     </template>
      </list-layout>
    </div>
  </template>
  
  <script>
  import ListLayout from '@/base/layout/ListLayout'
  
  import {
    getVenueCondition,softDelete
  } from '@/modules/venue/api'
  import conditionFilter from'@/modules/venue/components/venue-condition/Filter'
  export default {
    components: { ListLayout, conditionFilter },
    data() {
      return {
        filterForm: {
          tab: 'normal',
          page:1,
          page_size:10
        },
        tabArr: [
          {
            name: 'normal',
            label: '全部'
          },
          {
            name: 'running',
            label: '进行中'
          },
          {
            name: 'reserve_enable',
            label: '可预定'
          },
          {
            name: 'expired',
            label: '已过期'
          }
        ],
        thead: [
          // {
          //   type: 'selection',
          //   fixed: 'left',
          // },
          // {prop: 'code', label: '审批编号', width: 140},
          {prop: 'name', label: '场地名称',  minWidth: 170},
          // {prop: 'purpose', label: '场地标题',  minWidth: 170,},
          // {prop: 'type_text', label: '场地使用类型',  minWidth: 140, },
          {prop: 'category_name', label: '场地分类',  minWidth: 140, },
          {prop: 'organization_name', label: '所属架构',  minWidth:120},
          //  {prop: 'reserve_user_name', label: '申请人',  minWidth: 140, },
          // {prop: 'user_reserve_time', label: '使用时间',  minWidth: 120,},
          // {prop: 'user_reserve_time2', label: '使用时间',  minWidth: 120},
          {prop: 'reserve_time_text2', sortable: true, label: '开放时间',  minWidth: 200,},
          { type: 'operation', operations: [
              {command: 'details', text: '场地详情', },
              {command: 'delete', text: '删除', },
              // {command: 'approval_records', text: '审批记录', visible: (r, q) => q.tab === 'records'}
            ], 
            label: '操作',  minWidth:  160 
          },
        ],
      }
    },
    methods: {
      getList(q = {}) {
        return getVenueCondition({ ...this.filterForm, ...q })
      },
      // 刷新列表
      refreshList() {
        this.$refs.listLayout.getList()
      },
      handleOperation(e) {
        switch (e.command) {
          case 'details': {
            this.$router.push({
              name: 'VenueDetail',
              params: {
                id: e.row.id,
              },
            })
            break
          }
          case 'delete': {
            this.toDel(e.row)
            break
          }
          default:
        }
      },
      toDel(r) {
      this.$confirm(`是否删除【${r.name}】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'danger',
      })
        .then(() => {
          this.deleteArr([r.id])
        })
        .catch(() => {})
    },
    deleteArr(ids) {
      softDelete(ids)
        .then(({ data, msg }) => {
          this.$message.success(msg)
          this.refreshList()
        })
        .catch(() => {})
    },
      // 筛选回调
      onFilter(filterObj) {
        Object.assign(this.filterForm, filterObj, { page: 1 })
        this.$refs.listLayout.getList()
      },
    },
  }
  </script>
  
  <style lang="scss" scoped></style>
  